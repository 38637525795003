import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/circleci/project/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props } from 'docz';
import { Playground } from '~/components/playground/Playground';
import PageHeader from '~/components/PageHeader';
import InlineComponent from '~/components/InlineComponent';
import { DoDontGroup, DoDontCard } from '~/components/DoDont';
import { ImportStatement } from '~/components/ImportStatement';
import { SkeletonRectangle, SkeletonCircle, SkeletonWrapper } from '@entur/loader';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <PageHeader mdxType="PageHeader" />
    <Playground __position={1} __code={'<SkeletonWrapper style={{ width: \'100%\' }}>\n  <SkeletonCircle size=\"5em\" />\n  <SkeletonRectangle width=\"30%\" />\n  <SkeletonRectangle width=\"70%\" />\n  <SkeletonRectangle />\n</SkeletonWrapper>'} __scope={{
      props,
      DefaultLayout,
      Props,
      Playground,
      PageHeader,
      InlineComponent,
      DoDontGroup,
      DoDontCard,
      ImportStatement,
      SkeletonRectangle,
      SkeletonCircle,
      SkeletonWrapper,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <SkeletonWrapper style={{
        width: '100%'
      }} mdxType="SkeletonWrapper">
    <SkeletonCircle size="5em" mdxType="SkeletonCircle" />
    <SkeletonRectangle width="30%" mdxType="SkeletonRectangle" />
    <SkeletonRectangle width="70%" mdxType="SkeletonRectangle" />
    <SkeletonRectangle mdxType="SkeletonRectangle" />
  </SkeletonWrapper>
    </Playground>
    <h2 {...{
      "id": "komponenter"
    }}>{`Komponenter`}</h2>
    <h3 {...{
      "id": "skeleton"
    }}>{`Skeleton`}</h3>
    <ImportStatement imports="SkeletonRectangle" mdxType="ImportStatement" />
    <Props of={SkeletonRectangle} mdxType="Props" />
    <p><inlineCode parentName="p">{`Skeleton`}</inlineCode>{` er en firkantig boks, som man gir en høyde og bredde til.
Elementene har litt marigin seg i mellom per default.`}</p>
    <h3 {...{
      "id": "skeletoncircle"
    }}>{`SkeletonCircle`}</h3>
    <ImportStatement imports="SkeletonCircle" mdxType="ImportStatement" />
    <Props of={SkeletonCircle} mdxType="Props" />
    <p><inlineCode parentName="p">{`SkeletonCircle`}</inlineCode>{` er en sirkulær "Skeleton", for å illustrere sirkulære komponenter som vil vises etter lastingen er gjennomført.`}</p>
    <h3 {...{
      "id": "skeletonwrapper"
    }}>{`SkeletonWrapper`}</h3>
    <ImportStatement imports="SkeletonWrapper" mdxType="ImportStatement" />
    <Props of={SkeletonWrapper} mdxType="Props" />
    <p>{`Det ytterste elementet som `}<inlineCode parentName="p">{`Skeleton`}</inlineCode>{` og `}<inlineCode parentName="p">{`SkeletonCircle`}</inlineCode>{` pakkes inn i. Brukes for å gi nødvendige aria-labels o.l.`}</p>
    <h2 {...{
      "id": "retningslinjer-for-bruk"
    }}>{`Retningslinjer for bruk`}</h2>
    <p>{`Skeletons er placeholdere som visuelt kommuniserer at deler av innholdet er i ferd med å laste inn og hvordan innholdsoppsettet vil se ut.
Brukes når seksjoner på siden gradvis fylles med innhold, for eksempel tekst og bilder eller data i tabeller som dukker opp etter
hvert som de blir tilgjengelige. På denne måten gir vi brukerne en idé og forventning om hva slags innhold som skal komme,
i tillegg for å skape en oppfatning av redusert ventetid. Skeletons blir borte og erstattes med innhold så snart dataene er tilgjengelig.`}</p>
    <h3 {...{
      "id": "prinsipper"
    }}>{`Prinsipper`}</h3>
    <DoDontGroup mdxType="DoDontGroup">
  <DoDontCard title="Gjør" variant="success" textInBox mdxType="DoDontCard">
    <ul>
      <li>
        Bruk skeletons hvis data hentes fra baksystemer som har lang ventetid og
        er ikke umiddelbart tilgjengelige.
      </li>
      <li>
        Bruk skeletons hvis det laster inn mer enn ett element samtidig som
        krever en indikator.
      </li>
      <li>
        Bruk skeletons når innholdet har en fast størrelse og oppsettet er
        forutsigbart.
      </li>
    </ul>
  </DoDontCard>
  <DoDontCard title="Unngå" variant="warning" textInBox mdxType="DoDontCard">
    <ul>
      <li>
        Unngå å bruk skeletons hvis det er en langvarig prosess, f.eks
        importering av data eller opplasting av en fil. Bruk Progressbar i
        stedet.
      </li>
      <li>
        Unngå skeletons hvis det er en rask prosess som tar mindre enn 300 ms.
        Vurderer å bruk en vanlig Loader eller om du i det hele tatt trenger en
        lastetilstand.
      </li>
      <li>
        For en liten, inline handling som f.eks klikket på en knapp der
        handlingen kan ta tid, bruker vi en lastespinner i knappen istedet.
      </li>
    </ul>
  </DoDontCard>
    </DoDontGroup>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      